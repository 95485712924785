import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { provideRouter } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { OrderSummaryComponent } from './order-summary.component';

@NgModule({
  imports: [TranslateModule, MatMenuModule, CommonModule, OrderSummaryComponent],
  providers: [
    provideRouter([
      {
        path: '',
        component: OrderSummaryComponent,
      },
    ]),
  ],
})
export class OrderSummaryModule {}
