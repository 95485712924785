<div #orderSummaryWrapperTemplate class="wrapper">
  <div class="wrapper__inner">
    <ng-template [ngTemplateOutlet]="contentTemplate"></ng-template>
    <ng-template [ngTemplateOutlet]="backdropTemplate"></ng-template>
  </div>
</div>

<!-- backdrop template -->
<ng-template #backdropTemplate>
  <div class="wrapper__inner__backdrop" (click)="dismissRoute()"></div>
</ng-template>

<!-- content template -->
<ng-template #contentTemplate>
  <div class="wrapper__inner__content">
    <div class="wrapper__inner__content__header">
      <div class="wrapper__inner__content__header__label">
        <img
          class="wrapper__inner__content__header__label__icon"
          src="{{ iconInputFolder }}/header-icons/performance-analytics.svg"
        />
        <span class="body1--medium">
          {{ 'ORDER_SUMMARY.TAB_OUTLET.TITLE' | translate }}
        </span>
      </div>
      <img
        class="wrapper__inner__content__header__dismiss"
        src="{{ assetsPath }}/img/close.svg"
        (click)="dismissRoute()"
      />
    </div>
    <div class="wrapper__inner__content__body">
      <div class="wrapper__inner__content__body__section-one">
        <div class="wrapper__inner__content__body__section-one__title">
          <img src="" alt="" class="wrapper__inner__content__body__section-one__title__image" />
          <span class="wrapper__inner__content__body__section-one__title__label heading2--bold">
            {{ 'ORDER_SUMMARY.TAB_OUTLET.SECTION_ONE.SALUTATION' | translate : { salutation } }}
          </span>
        </div>
        <div class="wrapper__inner__content__body__section-one__content">
          <div class="wrapper__inner__content__body__section-one__content__time-span">
            <span
              class="wrapper__inner__content__body__section-one__content__time-span__label caption2--medium"
            >
              {{ 'ORDER_SUMMARY.TAB_OUTLET.SECTION_ONE.TIME_SELECTED.LABEL' | translate }}
            </span>
            <div
              #periodOptionsTrigger
              [matMenuTriggerFor]="periodOptions"
              [matMenuTriggerData]="{ triggerRef: periodOptionsTrigger }"
              class="wrapper__inner__content__body__section-one__content__time-span__trigger"
            >
              <img src="assets/img/chevron-blue.svg" alt="" />
              <span class="caption1--medium">
                <ng-template
                  [ngTemplateOutlet]="periodTemplate"
                  [ngTemplateOutletContext]="{
                    label: currentActivePeriod.label,
                    nMonths: currentActivePeriod.nMonths
                  }"
                ></ng-template>
              </span>
            </div>
          </div>
          <div class="wrapper__inner__content__body__section-one__content__country">
            <span
              class="wrapper__inner__content__body__section-one__content__country__label caption2--medium"
            >
              {{ 'ORDER_SUMMARY.TAB_OUTLET.SECTION_ONE.COUNTRY_SELECTED.LABEL' | translate }}
            </span>
            <div class="wrapper__inner__content__body__section-one__content__country__meta">
              <span
                class="wrapper__inner__content__body__section-one__content__country__meta__name caption1--medium"
                >{{ selectedCountry.name }}</span
              >
              <img
                src="{{ selectedCountry.flag }}"
                alt="{{ selectedCountry.name }} "
                class="wrapper__inner__content__body__section-one__content__country__meta__flag"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper__inner__content__body__section-two">
        <div class="wrapper__inner__content__body__section-two__header">
          <div class="wrapper__inner__content__body__section-two__header__stats heading2--bold">
            {{ 'ORDER_SUMMARY.TAB_OUTLET.SECTION_TWO.HEADER.STATS' | translate }}
            <ng-template
              [ngTemplateOutlet]="periodTemplate"
              [ngTemplateOutletContext]="{ label: timeStats.period, nMonths: timeStats.nMonths }"
            ></ng-template>
          </div>
          <div class="wrapper__inner__content__body__section-two__header__duration">
            <span
              class="wrapper__inner__content__body__section-two__header__duration__label body2--regular"
            >
              {{ 'ORDER_SUMMARY.TAB_OUTLET.SECTION_TWO.DURATION.LABEL' | translate }}</span
            >
            <span
              class="wrapper__inner__content__body__section-two__header__duration__value body2--regular"
              >{{ timeStats.span?.start }} - {{ timeStats.span?.end }}</span
            >
          </div>
        </div>
        <ng-container
          *ngTemplateOutlet="isFetchingOrders ? isFetchingOrdersTemplate : fetchedOrdersTemplate"
        ></ng-container>
        <ng-template #isFetchingOrdersTemplate>
          <loader [loading]="isFetchingOrders"></loader>
        </ng-template>
        <ng-template #fetchedOrdersTemplate>
          <div class="wrapper__inner__content__body__section-two__content">
            <ng-container
              *ngTemplateOutlet="
                ordersPresent
                  ? fetchedOrdersOrdersPresentTemplate
                  : fetchedOrdersNoOrdersPresentTemplate
              "
            ></ng-container>
            <ng-template #fetchedOrdersNoOrdersPresentTemplate>
              <ng-template
                [ngTemplateOutlet]="orderStatesTableTemplate"
                [ngTemplateOutletContext]="{ state: 'no-orders' }"
              ></ng-template>
            </ng-template>
            <ng-template #fetchedOrdersOrdersPresentTemplate>
              <ng-template
                [ngTemplateOutlet]="orderStatesTableTemplate"
                [ngTemplateOutletContext]="{ state: 'orders' }"
              ></ng-template>
            </ng-template>
          </div>
          <div class="wrapper__inner__content__body__section-two__no-content">
            <span
              class="wrapper__inner__content__body__section-two__no-content__instruction body2--medium"
            >
              {{ 'ORDER_SUMMARY.TAB_OUTLET.SECTION_TWO.NO_CONTENT.INSTRUCTION' | translate }}
            </span>
            <span
              class="wrapper__inner__content__body__section-two__no-content__action body2--bold"
              (click)="checkOutProducts()"
            >
              {{ 'ORDER_SUMMARY.TAB_OUTLET.SECTION_TWO.NO_CONTENT.ACTION' | translate }}
            </span>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>

<mat-menu #periodOptions class="order-summary-country-selection-menu-wrapper">
  <ng-template matMenuContent let-triggerRef="triggerRef">
    <div
      [ngStyle]="{
        width: triggerRef.offsetWidth - 17 + 'px'
      }"
      class="menu-wrapper"
    >
      <span
        *ngFor="let timePeriod of timePeriods"
        class="menu-wrapper__option caption1--regular"
        (click)="setActivePeriodSelection(timePeriod)"
      >
        <ng-template
          [ngTemplateOutlet]="periodTemplate"
          [ngTemplateOutletContext]="{ label: timePeriod.label, nMonths: timePeriod.nMonths }"
        ></ng-template>
      </span>
    </div>
  </ng-template>
</mat-menu>

<ng-template #periodTemplate let-label="label" let-nMonths="nMonths">
  {{ label | translate : { nMonths } }}
</ng-template>

<ng-template #orderStatesTableTemplate let-state="state">
  <div
    (click)="goToOrders()"
    class="wrapper__inner__content__body__section-two__content--{{ state }}"
  >
    <div
      *ngFor="let renderedOrderState of renderedOrderStates"
      class="wrapper__inner__content__body__section-two__content--{{ state }}__row"
    >
      <div
        *ngFor="let renderedOrderStateItem of renderedOrderState"
        class="wrapper__inner__content__body__section-two__content--{{ state }}__row__state"
      >
        <div
          class="wrapper__inner__content__body__section-two__content--{{
            state
          }}__row__state__label"
        >
          <span class="caption1--medium">{{ renderedOrderStateItem.label | translate }}</span>
        </div>
        <div
          class="wrapper__inner__content__body__section-two__content--{{
            state
          }}__row__state__content"
        >
          <img
            class="wrapper__inner__content__body__section-two__content--{{
              state
            }}__row__state__content__icon"
            src="{{ assetsPath + '/img/order-summary/' + renderedOrderStateItem.icon + '.svg' }}"
            alt=""
          />
          <div
            class="wrapper__inner__content__body__section-two__content--{{
              state
            }}__row__state__content__value {{ renderedOrderStateItem.key }} heading2--extra-bold"
          >
            {{ state === 'no-orders' ? 0 : renderedOrderStateItem.value }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
