/* eslint-disable prefer-destructuring */
import { NgFor, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, NavigationExtras, NavigationStart, Router } from '@angular/router';
import { country } from '@features/country/data';
import { user } from '@features/user/data';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, finalize, map, of, Subject, Subscription, take, takeUntil } from 'rxjs';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { GetOrderSummaryUseCase } from 'src/app/core/usecases/user/get-order-summary.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { LocalizedComponent } from '../base/localized.component';
import { OnboardingStoryGuideStateManager } from '../onboarding/state-manager/onboarding-story-guide.state-manager';
import { SelectCurrentActiveUserStepIndex } from '../onboarding/state-manager/selectors/selector-names';
import { LoaderComponent } from '../shared/components/loader/loader.component';
import { fullYearMonthDateFormat } from '../shared/constants';
import { ENGLISH_LANGUAGE } from '../shared/constants/country-language-codes-mapping.constants';
import { ORDER_SUMMARY_FEATURE } from '../shared/constants/feature-flags';
import { SharedOverlayService } from '../shared/services/shared-overlay.service';
import { SiteTranslateService } from '../shared/services/translate.service';
import { DateWrapperUtility } from '../shared/utilities/date-wrapper.utility';

interface StartAndEndDate {
  fromDate: string;
  toDate: string;
}

interface TimePeriod {
  label: string;
  value: string;
  nMonths?: number;
}

interface OrderStates {
  label: string;
  key: string;
  icon: string;
  value: number;
  uiPosition: number;
}

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
  standalone: true,
  imports: [NgTemplateOutlet, MatMenuModule, LoaderComponent, NgStyle, NgFor, TranslateModule],
})
export class OrderSummaryComponent extends LocalizedComponent implements OnInit, OnDestroy {
  @ViewChild('orderSummaryWrapperTemplate', { static: true })
  private _orderSummaryWrapperTemplate: ElementRef;

  private _onDestroy$: Subject<boolean>;

  public currentActivePeriod: TimePeriod;

  private _canViewOrderSummary: boolean;

  private _activePeriodChanged: boolean;

  public isFetchingOrders: boolean;

  public assetsPath: string;

  public iconInputFolder: string;

  public timePeriods: Array<TimePeriod>;

  public selectedCountry = country;

  public orderStates: Array<OrderStates>;

  public renderedOrderStates: Array<Array<OrderStates>>;

  public ordersPresent: boolean;

  public timeStats: {
    period?: string;
    span?: {
      start: string;
      end: string;
    };
    nMonths?: number;
  };

  public salutation: string;

  public isEnglishLanguage = false;

  private _languageChangeSubscription: Subscription;

  constructor(
    private _renderer2: Renderer2,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _sharedOverlayService: SharedOverlayService,
    private _onboardingStoryGuideStateManager: OnboardingStoryGuideStateManager,
    private _getOrderSummaryUseCase: GetOrderSummaryUseCase,
    private _getFeatureFlagUsecase: GetFeatureFlagUsecase,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
    private _siteTranslateService: SiteTranslateService,
    private _translateService: TranslateService,
  ) {
    super();
    this._onDestroy$ = new Subject<boolean>();
    this._canViewOrderSummary = false;
    this._activePeriodChanged = false;
    this.assetsPath = 'assets';
    this.iconInputFolder = `${this.assetsPath}/iconfont-input`;
    this.timePeriods = [
      {
        label: 'ORDER_SUMMARY.TAB_OUTLET.SECTION_ONE.TIME_SELECTED.OPTIONS.THIS_MONTH',
        value: 'currentMonth',
      },
      {
        label: 'ORDER_SUMMARY.TAB_OUTLET.SECTION_ONE.TIME_SELECTED.OPTIONS.LAST_MONTH',
        value: 'lastMonth',
      },
      {
        label: 'ORDER_SUMMARY.TAB_OUTLET.SECTION_ONE.TIME_SELECTED.OPTIONS.LAST_N_MONTHS',
        value: 'last3Months',
        nMonths: 3,
      },
      {
        label: 'ORDER_SUMMARY.TAB_OUTLET.SECTION_ONE.TIME_SELECTED.OPTIONS.LAST_N_MONTHS',
        value: 'last6Months',
        nMonths: 6,
      },
      {
        label: 'ORDER_SUMMARY.TAB_OUTLET.SECTION_ONE.TIME_SELECTED.OPTIONS.LAST_YEAR',
        value: 'lastYear',
      },
    ];
    this.isFetchingOrders = false;
    this.timeStats = {};
  }

  ngOnInit(): void {
    this.isEnglishLanguage = this._siteTranslateService.getCurrentLanguage() === ENGLISH_LANGUAGE;
    this._languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: (event: LangChangeEvent) => {
        this.isEnglishLanguage = event.lang === ENGLISH_LANGUAGE;
      },
    });
    this._getFeatureFlagUsecase
      .execute(ORDER_SUMMARY_FEATURE)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe({
        next: (verdict) => {
          if (verdict) {
            this._checkIfThereIsAnyObscuringElement();
            this._listenForRouteParams();
            this._listenForNavigationAwayFromPopup();
          } else {
            this.dismissRoute();
          }
        },
      });
    this._getUser();
  }

  private _getUser(): void {
    const name = user.fullName;
    this.salutation = name!;
  }

  /**
   * Listen for route params, so that as a user reloads the page, this
   * information can directly be used
   */
  private _listenForRouteParams(): void {
    this._activatedRoute.params.pipe(takeUntil(this._onDestroy$)).subscribe({
      next: (paramMap) => {
        const { period } = paramMap;
        this.currentActivePeriod = this.timePeriods.find(
          (timePeriod) => timePeriod.value === period,
        )!;
        if (!this.isFetchingOrders) {
          this._getOrdersForSelectedPeriod(period);
        }
      },
    });
  }

  /**
   * So, we don't need to actually persist this component, meaning that as the
   * user clicks on button to navigate to other places in the app, we should
   * dismiss this outlet
   */
  private _listenForNavigationAwayFromPopup(): void {
    const navigatedToOtherRoot$: Subject<boolean> = new Subject<boolean>();
    const rootUrlToMatchAgainst = this._router.url.split(`(${this._activatedRoute.outlet}`)[0];
    this._router.events.pipe(takeUntil(navigatedToOtherRoot$)).subscribe({
      next: (ev) => {
        if (ev instanceof NavigationStart) {
          const newTargetURL = ev.url.split(`(${this._activatedRoute.outlet}`)[0];
          if (rootUrlToMatchAgainst !== newTargetURL) {
            navigatedToOtherRoot$.next(true);
            navigatedToOtherRoot$.complete();
            this._router.navigateByUrl(newTargetURL);
          }
        }
      },
    });
  }

  /**
   * We need to check and ensure that the outlet will play well with
   * other content that may compete for the overlay.
   *
   * For example, is there an existing overlay? Are we in the onboarding
   * journey? Such are competing for the position, and to ensure a great
   * seamless experience, we need to only show this path, if there is
   * no competition.
   */
  private _checkIfThereIsAnyObscuringElement(): void {
    const rootUrlToMatchAgainst = this._router.url.split(`(${this._activatedRoute.outlet}`)[0];
    combineLatest({
      noBlockingOverlay: this._sharedOverlayService
        .getOverlayType()
        .pipe(
          map((overlayType) => overlayType === 'noOverlay' || overlayType === 'lastOnboardingStep'),
        ),
      isOnboardingOverlayBlocking:
        /**
         * If the user is attempting to navigate to the products, then we check if
         * the onboarding manager is intialized, that is, they can be able to view
         * the rest of the UI, because we display the onboarding in the products
         * page
         *
         * If the manager is intialized and the URL is products, then we resolve th
         * onboarding completion and if it is true, we can show the user the summary
         *
         * Otherwise, if the user is on any path that is not matching products root,
         * then they can always see the order summary.
         */
        rootUrlToMatchAgainst === this._appURLs.PRODUCTS_V2_URL &&
        this._onboardingStoryGuideStateManager.managerIsInitialized()
          ? this._onboardingStoryGuideStateManager
              .selectStatePiece(SelectCurrentActiveUserStepIndex)
              /**
               * So, a user can only see the summary in UI sections which are not
               * past a given onboarding step.
               */
              .pipe(map((step) => step < 5))
          : of(false),
    })
      .pipe(takeUntil(this._onDestroy$))
      .subscribe({
        next: ({ noBlockingOverlay, isOnboardingOverlayBlocking }) => {
          this._canViewOrderSummary = noBlockingOverlay && !isOnboardingOverlayBlocking;
          if (!this._canViewOrderSummary) {
            this.dismissRoute();
          } else {
            /**
             * Wo, we can be able to view the order summary, so we not calculate the
             * position
             */
            this._calculateAppropriateHeight();
          }
        },
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next(true);
    this._onDestroy$.complete();
  }

  /**
   * Calculate the appropriate height for the element
   */
  private _calculateAppropriateHeight(): void {
    const infoHeaderElement = document.getElementsByTagName('info-header')[0];
    const infoHeaderElementDims = infoHeaderElement?.getBoundingClientRect();
    const infoHeaderHeight = infoHeaderElementDims?.y < 0 ? 0 : infoHeaderElementDims.height;
    const appMainHeaderDims = document
      .getElementsByTagName('app-main-header')[0]
      ?.getBoundingClientRect();
    const appMainHeaderHeight = appMainHeaderDims?.y < 0 ? 0 : appMainHeaderDims.height;
    const appStickyHeader = document.getElementsByClassName('navigation-lower-section')[0];
    const appStickyHeaderDims = appStickyHeader?.getBoundingClientRect();
    const appStickyHeaderHeight = appStickyHeaderDims.y < 0 ? 0 : appStickyHeaderDims.height;

    /**
     * use the navigation lower section to find it's end on the UI, with
     * respect to y axis, then this we calculate the offset from the start
     * and use it
     */

    let calculatedOffsetFromTopHeight =
      infoHeaderHeight + appMainHeaderHeight + appStickyHeaderHeight;
    const headerElementPadding = parseInt(
      window.getComputedStyle(document.getElementsByClassName('info-header-wrapper')[0]).paddingTop,
      10,
    );
    if (infoHeaderHeight > 0 && appMainHeaderHeight > 0 && appStickyHeaderHeight > 0) {
      calculatedOffsetFromTopHeight -= headerElementPadding;
    } else {
      if (infoHeaderHeight === 0 && appMainHeaderHeight === 0) {
        calculatedOffsetFromTopHeight += headerElementPadding;
      }
    }

    this._renderer2.setStyle(
      this._orderSummaryWrapperTemplate.nativeElement,
      'height',
      `calc(100% - ${calculatedOffsetFromTopHeight}px)`,
    );
  }

  @HostListener('window:scroll')
  onWindowScroll(): void {
    if (this._canViewOrderSummary) {
      /**
       * We don't want to have unneded UI calculations, if we cannot viw the order summary
       * because of the reasons listed in _checkIfThereIsAnyObscuringElement method
       */
      this._calculateAppropriateHeight();
    }
  }

  /**
   *
   * @param period
   *
   * Set the active period by updating the url path, and also making call to
   * the backend to get content for such period
   */
  public setActivePeriodSelection(period: TimePeriod): void {
    this._activePeriodChanged = true;
    const { value } = period;
    this.currentActivePeriod = period;
    this._doRouterNavigation([{ outlets: { popup: ['order-summary', value] } }]);
    this._getOrdersForSelectedPeriod(value);
  }

  /**
   *
   * @param period
   *
   * Common method to get orders for selected period
   */
  private _getOrdersForSelectedPeriod(period: string): void {
    const payload = this._doCalculateStartAndEndDates(period);
    const { value } = this.currentActivePeriod;
    this._logMixpanelEventUseCase.execute({
      eventName: 'order_summary_date_period_selected',
      payload: {
        date_period: value[0].toUpperCase() + value.split('').slice(1).join(''),
      },
    });
    this._calculateUITimeSpan(payload);
    this.isFetchingOrders = true;
    this._getOrderSummaryUseCase
      .execute(payload)
      .pipe(
        take(1),
        finalize(() => {
          this.isFetchingOrders = false;
        }),
      )
      .subscribe({
        next: (orderSummary: any) => {
          const orderStatesMeta: any = {
            confirmedOrders: {
              label: 'Confirmed',
              icon: 'confirmed',
              key: 'confirmed',
              uiPosition: 1,
            },
            placedOrders: {
              label: 'Placed',
              icon: 'placed',
              key: 'placed',
              uiPosition: 2,
            },
            suspendedOrders: {
              label: 'Suspended',
              icon: 'suspended',
              key: 'suspended',
              uiPosition: 3,
            },
            deliveriesInProgress: {
              label: 'Deliveries In Progress',
              icon: 'deliveries-in-progress',
              key: 'deliveriesInProgress',
              uiPosition: 4,
            },
            refundedOrders: {
              label: 'Refunded',
              icon: 'refunded',
              key: 'refunded',
              uiPosition: 5,
            },
            deliveredOrders: {
              label: 'Delivered',
              icon: 'delivered',
              key: 'delivered',
              uiPosition: 6,
            },
          };
          const orderStates: Array<OrderStates> = [];
          for (const orderState in orderSummary) {
            if (orderState in orderStatesMeta) {
              orderStates.push({
                value: orderSummary[orderState],
                ...orderStatesMeta[orderState],
              });
            }
          }
          this.orderStates = orderStates.sort((a, b) => (a.uiPosition > b.uiPosition ? 1 : -1));
          this.orderStates.forEach((orderState) => {
            if (orderState.value !== 0) {
              this.ordersPresent = true;
            }
          });
          this._createRenderedOrderStates();
        },
      });
  }

  /**
   *
   * @param payload
   *
   * We need to display the time span on the UI. This will be:
   *
   * 1. The period, for example December or Last Year (if a non definite period)
   *
   * 2. The start and end of the period for example 1st Dec - 31st Dec
   */
  private _calculateUITimeSpan(payload: StartAndEndDate): void {
    const { value } = this.currentActivePeriod;
    if (value === 'currentMonth' || value === 'lastMonth') {
      /**
       * Get the actual month
       */
      const { month } = DateWrapperUtility.getFormattedDateParts(
        payload.fromDate,
        {
          month: 'MMMM',
        },
        this.isEnglishLanguage ? 'en' : 'ar',
      );
      this.timeStats.period = month;
    } else {
      /**
       * Set the label
       */
      const { label, nMonths } = this.currentActivePeriod;
      this.timeStats.period = label;
      this.timeStats.nMonths = nMonths;
    }
    /**
     * Now calculate time spans
     */
    const periodStart = DateWrapperUtility.getFormattedDateParts(
      payload.fromDate,
      {
        day: 'Do',
        month: 'MMM',
        year: 'YYYY',
      },
      this.isEnglishLanguage ? 'en' : 'ar',
    );
    const periodEnd = DateWrapperUtility.getFormattedDateParts(
      payload.toDate,
      {
        day: 'Do',
        month: 'MMM',
        year: 'YYYY',
      },
      this.isEnglishLanguage ? 'en' : 'ar',
    );
    this.timeStats.span = {
      start: `${periodStart.day} ${periodStart.month} ${periodStart.year}`,
      end: `${periodEnd.day} ${periodEnd.month} ${periodStart.year}`,
    };
  }

  private _createRenderedOrderStates(): void {
    const statesPerRow = 2;
    const renderedOrderStates: any[] = [];
    let renderedOrderStatesBin: any[] = [];
    this.orderStates.forEach((state) => {
      state.label = `ORDER_SUMMARY.TAB_OUTLET.SECTION_TWO.ORDER_STATES.${state.label
        ?.replace(/ /g, '_')
        .toUpperCase()}`;
      renderedOrderStatesBin.push(state);
      if (renderedOrderStatesBin.length === statesPerRow) {
        renderedOrderStates.push(renderedOrderStatesBin);
        renderedOrderStatesBin = [];
      }
    });
    this.renderedOrderStates = renderedOrderStates;
  }

  private _doCalculateStartAndEndDates(periodCode: string): StartAndEndDate {
    switch (periodCode) {
      case 'currentMonth':
        return this._doCalculateStartAndEndDatesForCurrentMonth();
      case 'lastMonth':
        return this._doCalculateStartAndEndDatesForLastMonth();
      case 'last3Months':
        return this._doCalculateTimeSpanForNMonthsFromNow(3);
      case 'last6Months':
        return this._doCalculateTimeSpanForNMonthsFromNow(6);
      case 'lastYear':
        return this._doCalculateStartAndEndDatesForLastYear();
      default:
        throw new Error(`Invalid periodCode: ${periodCode}`);
    }
  }

  private _doCalculateStartAndEndDatesForCurrentMonth(): StartAndEndDate {
    const now = new Date();
    return {
      fromDate: this._formatDateToAcceptedServerFormat(
        DateWrapperUtility.getTheStartOfATimeUnit(now, 'month'),
      ),
      toDate: this._formatDateToAcceptedServerFormat(now),
    };
  }

  private _doCalculateStartAndEndDatesForLastMonth(): StartAndEndDate {
    const lastMonth = DateWrapperUtility.calculateTimeUnitsFromGivenPoint(
      'month',
      new Date(),
      1,
      'backward',
    );
    return {
      fromDate: this._formatDateToAcceptedServerFormat(
        DateWrapperUtility.getTheStartOfATimeUnit(lastMonth, 'month'),
      ),
      toDate: this._formatDateToAcceptedServerFormat(
        DateWrapperUtility.getTheEndOfATimeUnit(lastMonth, 'month'),
      ),
    };
  }

  private _doCalculateStartAndEndDatesForLastYear(): StartAndEndDate {
    const lastYear = DateWrapperUtility.calculateTimeUnitsFromGivenPoint(
      'year',
      new Date(),
      1,
      'backward',
    );
    return {
      fromDate: this._formatDateToAcceptedServerFormat(
        DateWrapperUtility.getTheStartOfATimeUnit(lastYear, 'year'),
      ),
      toDate: this._formatDateToAcceptedServerFormat(
        DateWrapperUtility.getTheEndOfATimeUnit(lastYear, 'year'),
      ),
    };
  }

  /**
   *
   * @param monthCount
   *
   * A shared function for calculating the time span of N months
   *
   * For example 3 months ago, pass in 3, 6 months ago, pass in 6
   */
  private _doCalculateTimeSpanForNMonthsFromNow(monthsAgo: number): StartAndEndDate {
    const lastMonth = DateWrapperUtility.calculateTimeUnitsFromGivenPoint(
      'month',
      new Date(),
      1,
      'backward',
    );
    return {
      fromDate: this._formatDateToAcceptedServerFormat(
        DateWrapperUtility.getTheStartOfATimeUnit(
          DateWrapperUtility.calculateTimeUnitsFromGivenPoint(
            'month',
            lastMonth,
            monthsAgo - 1,
            'backward',
          ),
          'month',
        ),
      ),
      toDate: this._formatDateToAcceptedServerFormat(
        DateWrapperUtility.getTheEndOfATimeUnit(lastMonth, 'month'),
      ),
    };
  }

  /**
   *
   * @param date
   *
   * Common method that accepts a date and returns a formated date format
   * which the server is compatible with
   */
  private _formatDateToAcceptedServerFormat(date: Date): string {
    return DateWrapperUtility.formatDateToString(date, fullYearMonthDateFormat);
  }

  /**
   * Dismisses the order summary opened route
   */
  public dismissRoute(): void {
    this._doRouterNavigation([{ outlets: { popup: null } }], { queryParamsHandling: 'preserve' });
  }

  public checkOutProducts(): void {
    const rootUrlToMatchAgainst = this._router.url.split(`(${this._activatedRoute.outlet}`)[0];
    const checkOutProductsUrl = this._appURLs.RECOMMENDED_PRODUCTS_PAGE_URL;
    if (rootUrlToMatchAgainst === checkOutProductsUrl) {
      /**
       * We are already on the check out products URL,
       * so we simply dismiss this route
       */
      this.dismissRoute();
    } else {
      /**
       * We navigate to the check out products route
       */
      this._doRouterNavigation([checkOutProductsUrl, { outlets: { popup: null } }]);
    }
  }

  /**
   * Common method to handle route navigation
   */
  private _doRouterNavigation(commands: any[], extras?: NavigationExtras): void {
    this._router.navigate(commands, extras);
  }

  /**
   * Navigate to the orders page
   */
  public goToOrders(): void {
    const { value } = this.currentActivePeriod;
    this._router.navigateByUrl(this._appURLs.ORDERS_URL);
    this._logMixpanelEventUseCase.execute({
      eventName: 'order_summary_order_view',
      payload: {
        date_period: value[0].toUpperCase() + value.split('').slice(1).join(''),
      },
    });
  }
}
